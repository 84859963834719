<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Vamos começar preenchendo as informações básicas
    </div>
    <v-row
      id="analise-setorizada-nome"
      class="mx-auto"
    >
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            id="fs-nome-analise-setorizada"
            label="Nome da análise setorizada"
            outlined
            :error-messages="errors"
            v-model="nome"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <div id="analise-setorizada-bdgd-versao">
      <v-row
        id="analise-setorizada-mes"
        class="mx-auto"
      >
        <v-col
          cols="12"
          id="fs-mes-dados-analise-setorizada"
        >
          <select-all-bdgd-versions
            label="Versão da BDGD"
            :companyId="companyId"
            @bdgdVersionSelected="bdgdVersionSelected"
          />
        </v-col>
      </v-row>
    </div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Selecione uma setorização para sua análise
    </div>
    <v-row class="mx-auto">
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            id="selecao-setorizacao"
            label="Setorização"
            placeholder="Selecione uma setorização"
            readonly
            outlined
            v-model="setorizacao.nome"
            @focus.close="dialogSetorizacao = true"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row
      id="setorizacao-mapa"
      class="mx-auto"
    >
      <v-col cols="12">
        <mapa-setorizacao :setorizacaoId="setorizacao.id" />
      </v-col>
    </v-row>
    <dialog-seletor-setorizacao
      :month="data"
      :items="items"
      :bdgdVersion="bdgdVersion"
      :activated.sync="dialogSetorizacao"
      @selected="setSetorizacao($event)"
    />
  </div>
</template>

<script>
import dateFormatterMixins from '@/mixins/dateFormatterMixins';

export default {
  name: 'FirstTabContent',

  mixins: [dateFormatterMixins],

  components: {
    SelectAllBdgdVersions: () =>
      import('@/components/general/SelectAllBdgdVersions.vue'),
    DialogSeletorSetorizacao: () =>
      import('@/components/general/seletores/DialogSeletorSetorizacao.vue'),
    MapaSetorizacao: () =>
      import('@/components/analises-setorizadas/MapaSetorizacao.vue')
  },

  data() {
    return {
      nome: null,
      mes: null,
      bdgdVersion: null,
      setorizacao: {
        id: null,
        nome: null
      },
      dialogSetorizacao: false
    };
  },

  mounted() {
    if (this.items) this.fillInputs();
  },

  props: {
    items: {
      type: Object
    }
  },

  computed: {
    data() {
      return this.mes ? `${this.mes}-01` : null;
    },

    companyId() {
      return this.$store.getters.getCompanyId;
    }
  },

  watch: {
    nome() {
      this.emitEventDataUpdated();
    },

    mes() {
      // this.setorizacao = { nome: null };
      this.emitEventDataUpdated();
    },

    bdgdVersion() {
      // this.setorizacao = { nome: null };
      this.emitEventDataUpdated();
    },

    setorizacao() {
      this.emitEventDataUpdated();
    }
  },

  methods: {
    bdgdVersionSelected(bdgdVersion) {
      this.mes = this.formatMonthToPicker(bdgdVersion.mes);
      this.bdgdVersion = bdgdVersion.versao;
    },

    setSetorizacao(setorizacao) {
      this.setorizacao = setorizacao;
      this.dialogSetorizacao = false;
    },

    emitEventDataUpdated() {
      this.$emit('data:updated', {
        nome: this.nome,
        mes: this.mes,
        data: this.data,
        bdgdVersion: this.bdgdVersion,
        setorizacao: this.setorizacao
      });
    },

    fillInputs() {
      this.nome = this.items.nome;
      // this.mes = this.items.mes;
      // this.bdgdVersion = this.items.bdgdVersion;
      this.setorizacao = this.items.setorizacao;
    }
  }
};
</script>
